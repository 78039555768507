
export const Tab=[
    {
        title:"ALL",
        id:"all",
    },
    {
        title:"WORDPRESS",
        id:"wordpress",
    },
    {
        title:"WOOCOMMERCE",
        id:"woocommerce",
    },
    {
        title:"LEARNDASH",
        id:"learndash",
    },
    {
        title:"ELEMENTOR",
        id:"pagebuilder",
    },
]
